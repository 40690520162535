import React from "react";
import Header from "../components/header"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"
import conductorLogo from "../images/conductorLogo.svg"
import arrow from "../images/arrow-forward-conductor.svg"
import facebook from "../images/facebook.svg"
import kik from "../images/kik.svg"
import line from "../images/line.svg"
import mail from "../images/mail.svg"
import skype from "../images/skype.svg"
import slack from "../images/slack.svg"
import tslogoWhiteBackground from "../images/tslogo.svg"
import Background from '../images/conductor.png';

const Page = class extends React.Component {
    render() {
        return(
            <>
                <Helmet>
                    <title>ChatForge | Conductor</title>
                    <meta name="description" content="Conductor is a conversational support solution"/>
                </Helmet>
                <body class="mainPage">
                    <Header showMenu={false} />
                    <div class="content">
                        <div class="info-page-intro">
                            <div class="info-page-intro-details">
                                <div class="info-page-intro-title conductor-text">
                                    <img class="info-page-intro-logo" src={conductorLogo} alt="Conductor Logo"/>
                                    <span>Conductor</span>
                                </div>
                                <p>Conductor is a conversational support solution that combines the ease and efficiency of using a chatbot with the depth and experience human-based live chat can provide.</p>
                                <p>Conductor is generally available for <a target="_blank" rel="noopener noreferrer" class="conductor-links" href="http://www.slack.com">Slack</a> & <a rel="noopener noreferrer" target="_blank" class="conductor-links" href="http://www.drift.com">Drift </a> as chat providers, 
                                with <a target="_blank" class="conductor-links" rel="noopener noreferrer" href="https://teams.microsoft.com/start"> Microsoft Teams</a> in beta.</p>
                                <a href="/" target="_blank" rel="noopener noreferrer">
                                    <button className="conductor-button longbutton" >Start a chat with us to learn more about Conductor</button>
                                </a>
                            </div>
                            <div class="info-page-intro-image" style={{"backgroundImage": `url(${Background})`}}/>
                           
                        </div>
                    </div>
                    <div className="section-wide info-page-section">
                        <div class="flex-container info-page-cards">
                            <div class="flex-item primaryBox">
                                <div class="title-container">
                                    <p class="title conductor-text">Channels</p>
                                </div>
                                <p class="box-text">Let your end users reach you from wherever they want and without leaving their favourite chat apps.</p>
                                <div class="info-icon-list">
                                    <img src={slack} alt="slack"/>
                                    <img src={facebook} alt="facebook"/>
                                    <img src={skype} alt="skype"/>
                                </div>
                                <button style={{"display":"none"}} class="info-read-more">Read More</button>
                            </div>
                            <img class="flex-item arrow conductor-text" src={arrow} alt="arrow"/>
                            <div class="flex-item primaryBox">
                                <div class="title-container">
                                    <p class="title conductor-text">Smart FAQs</p>
                                </div>
                                <p class="box-text">Automating the frequently asked but easy to answer queries frees your team to use their experience to solve more substantial and important problems or handle more nuanced queries.</p>
                            </div>
                            <img class="flex-item arrow conductor-text" src={arrow} alt="arrow"/>
                            <div class="flex-item primaryBox">
                                <div class="title-container">
                                    <p class="title conductor-text">Services &amp; Integrations</p>
                                </div>
                                <p class="box-text">Using the power of talksuite we can integrate any additional service or integrations you need that make your front-line support as efficient and usable as possible.</p>
                                <div class="powered-by">
                                    <span>Powered by</span>
                                    <a target="_blank" rel="noopener noreferrer" href="https://people-first.com/talksuite" >
                                        <img src={tslogoWhiteBackground} alt="talksuite logo" height="18"/>
                                    </a>
                                </div>
                            </div>
                            <img class="flex-item arrow conductor-text" src={arrow} alt="arrow"/>
                            <div class="flex-item primaryBox">
                                <div class="title-container">
                                    <p class="title conductor-text">Conductor</p>
                                </div>
                                <p class="box-text">If your end user’s query can’t be handled automatically, Conductor swaps that conversation from bot to agent and can appropriately route them to the most appropriate person or team.</p>
                            </div>
                            <img class="flex-item arrow conductor-text" src={arrow} alt="arrow"/>
                            <div class="flex-item primaryBox">
                                <div class="title-container">
                                    <p class="title conductor-text">Your systems</p>
                                </div>
                                <p class="box-text">You can use any existing chat system that you have in your business or we can help you set up a new one.</p>
                                <div class="info-icon-list">
                                    <img src={mail} alt="mail"/>
                                    <img src={line} alt="line"/>
                                    <img src={kik} alt="kik"/>
                                </div>
                                <button style={{"display":"none"}} class="info-read-more">Read More</button>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </body>
            </>
        )   
    }    
}

export default Page
